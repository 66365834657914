/*
author: Alban Painchault
 */

 /*
 * Container
 */

.ql-container {
  @apply h-full relative border m-0
  /*  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif; 
  font-size: 13px; 
  height: 100%;
  margin: 0px;
  position: relative;
  */
}
.ql-container.ql-disabled .ql-tooltip {
  @apply hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  @apply pointer-events-auto
}


.ql-snow a {
  @apply text-info
}
/*
.ql-container.ql-snow {
  border: 1px solid #ccc;
}
*/
/*
* Clipboard
*/
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  @apply m-0 p-0
}

/**
*
* Editor
*
**/

/* No content - placeholder */
.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.ql-editor {
  @apply h-full text-left whitespace-pre-wrap break-words outline-none overflow-y-auto p-2
  /*
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  */
}
.ql-editor > * {
  @apply cursor-text
}

/*
List
*/
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol,
.ql-editor ul {
  @apply pl-4
}
.ql-editor ol > li,
.ql-editor ul > li {
  @apply list-none
}
.ql-editor ul > li::before {
  content: '\2022';
}
.ql-editor ul[data-checked=true],
.ql-editor ul[data-checked=false] {
  pointer-events: none;
}
.ql-editor ul[data-checked=true] > li *,
.ql-editor ul[data-checked=false] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before,
.ql-editor ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before {
  content: '\2611';
}
.ql-editor ul[data-checked=false] > li::before {
  content: '\2610';
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}

/*
Video
*/
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {

  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

/*
Background color
*/
/*
.ql-editor .ql-bg-black {
  @apply bg-black
}
.ql-editor .ql-bg-red-600 {
  @apply bg-red-600
}
.ql-editor .ql-bg-orange {
  @apply bg-orange-600
}
.ql-editor .ql-bg-yellow {
  @apply bg-yellow-600
}
.ql-editor .ql-bg-green {
  @apply bg-green-600
}
.ql-editor .ql-bg-blue {
  @apply bg-blue-600
}
.ql-editor .ql-bg-purple {
  @apply bg-purple-600
}
*
/
/*
Text Color
*/
/*
.ql-editor .ql-color-white {
  @apply text-white
}
.ql-editor .ql-color-red {
  @apply text-red
}
.ql-editor .ql-color-orange {
  @apply text-orange
}
.ql-editor .ql-color-yellow {
  @apply text-yellow
}
.ql-editor .ql-color-green {
  @apply text-green;
}
.ql-editor .ql-color-blue {
  @apply text-blue
}s
.ql-editor .ql-color-purple {
  @apply text-purple
}
*/
/*
Fonts 
*/

.ql-editor .ql-font-roboto {
  @apply font-Roboto
}
.ql-editor .ql-font-opensans {
  @apply font-OpenSans
}
.ql-editor .ql-font-montserrat {
  @apply font-Montserrat
}
.ql-editor .ql-font-merriweather {
  @apply font-Merriweather
}
.ql-editor .ql-font-cormorant {
  @apply font-Cormorant
}
.ql-editor .ql-font-dancingscript {
  @apply font-DancingScript
}
.ql-editor .ql-font-eczar {
  @apply font-Eczar
}

/*
Text size
*/
.ql-editor .ql-size-extrasmall {
  @apply text-xs
}
.ql-editor .ql-size-small {
  @apply text-sm
}
.ql-editor .ql-size-medium {
  @apply text-base
}
.ql-editor .ql-size-large {
  @apply text-xl
}
.ql-editor .ql-size-extralarge {
  @apply text-2xl
}

/*
Align
*/
.ql-editor .ql-align-center {
  @apply text-center
}
.ql-editor .ql-align-justify {
  @apply text-justify
}
.ql-editor .ql-align-right {
  @apply text-right
}






/*
.ql-snow {
  box-sizing: border-box;
}
.ql-snow * {
  box-sizing: border-box;
}
*/

.ql-snow .ql-hidden {
  @apply hidden
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  @apply hidden
}



.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}
.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  @apply inline-block
  /*
  display: inline-block;
  vertical-align: middle;
  */
}
.ql-snow .ql-formats:after {
  clear: both;
  content: '';
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444;
}
.ql-snow .ql-empty {
  fill: none;
}
.ql-snow .ql-even {
  fill-rule: evenodd;
}
.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}
.ql-snow .ql-transparent {
  opacity: 0.4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

/* A */
.ql-snow .ql-editor a {
  @apply underline
}

/* Blockquote */
.ql-snow .ql-editor blockquote {
  @apply border-solid border-neutreLight border-l-4 my-1 pl-4
}

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}
.ql-snow .ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

.ql-snow .ql-editor img {
  @apply mx-auto
}

.ql-snow .ql-editor .block-img {
  @apply m-2
}

/*
* Toolbar
*/


.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: '';
  display: table;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  @apply h-7 w-8 inline-block float-left border-none cursor-pointer px-1.5 py-1;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  @apply w-full float-left
}
.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  @apply outline-none
}
.ql-snow.ql-toolbar input.ql-image[type=file],
.ql-snow .ql-toolbar input.ql-image[type=file] {
  @apply hidden
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply text-info
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  @apply fill-info
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  @apply stroke-info
}
@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

/* Toolbar Picker (list) */
.ql-snow .ql-picker {
  @apply relative text-neutre border-none inline-block float-left
  /*
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;

  */
}
.ql-snow .ql-picker-label {
  @apply w-full w-full relative inline-block cursor-pointer pr-1 pl-1
}
.ql-snow .ql-picker-label::before {
  @apply inline-block 
}
.ql-snow .ql-picker-options {
  @apply hidden absolute whitespace-nowrap bg-white px-2 py-1
}
/*
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
  */
.ql-snow .ql-picker-options .ql-picker-item {
  @apply block cursor-pointer py-1.5 
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  @apply top-full block mt-1.5 z-[1]

}
.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  @apply w-8
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  @apply h-7 w-8 px-1.5 py-1;
}

.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  @apply right-1
}

.ql-snow .ql-icon-picker .ql-picker-options {
  @apply px-0 py-1
}
.ql-snow .ql-icon-picker .ql-picker-item {
  @apply h-7 w-8 px-0.5 py-1
}

/* Color picker*/
.ql-snow .ql-color-picker .ql-picker-options {
  @apply w-24 p-2
/*
  padding: 3px 5px;
  width: 152px;
*/
}
.ql-snow .ql-color-picker .ql-picker-item {
  @apply h-5 w-5 float-left border border-transparent m-0.5 p-0 
  /*
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
  */
}

/* Modify */
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  @apply hidden
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label='']),
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label='']),
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label='']),
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label='']) {
  @apply before:content-[attr(data-label)]
  /*content: attr(data-label); */
}

/*
Font
*/
.ql-snow .ql-picker.ql-font {
  @apply w-fit
}
/* font option - apply font-family */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=opensans] {
  @apply before:font-OpenSans;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto] {
  @apply before:font-Roboto;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=montserrat] {
  @apply before:font-Montserrat;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=merriweather] {
  @apply before:font-Merriweather;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=cormorant] {
  @apply before:font-Cormorant;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=dancingscript] {
  @apply before:font-DancingScript;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=eczar] {
  @apply before:font-Eczar;
}
/*
UI Text size
*/
.ql-snow .ql-picker.ql-size {
  @apply w-fit
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extrasmall] {
  @apply before:text-xs
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small] {
  @apply before:text-sm
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large] {
  @apply before:text-xl
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extralarge] {
  @apply before:text-3xl
}

/*
Color background
*/
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  @apply bg-white
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  @apply bg-black
}


/*
UI Toolbar
*/

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 8px;
}

.ql-toolbar.ql-snow .ql-formats {
  @apply mr-1
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  @apply border-success
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  @apply border-success
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  @apply border-red-600
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  @apply border-t-0
}


/*
Tooltip
*/
/*
.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}
.ql-snow .ql-tooltip::before {
  content: "URL:";
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type=text] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: 'Edit';
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remove';
  margin-left: 8px;
}
.ql-snow .ql-tooltip a {
  line-height: 26px;
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.ql-snow .ql-tooltip.ql-editing input[type=text] {
  display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: 'Save';
  padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Enter link:";
}
.ql-snow .ql-tooltip[data-mode=formula]::before {
  content: "Enter formula:";
}
.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "Enter video:";
}
*/





